<template>
	<div class="contacts-wrap" id="contacts">
		<div class="container">
			<p class="sub-title"
			data-aos="fade-right"
			data-aos-delay="300"
			data-aos-duration="1000"
			>ми на зв'язку!</p>
		</div>
		<div class="container cols-2">
			<div class="contacts">
				<h2 
				data-aos="fade-left"
				data-aos-delay="300"
				data-aos-duration="1000"

				class="title">контакти</h2>

				<p 
				data-aos="fade-left"
				data-aos-delay="300"
				data-aos-duration="1000"

				class="address"><a href="https://www.google.com/maps/dir//49.764711,24.0158236/@49.7652216,24.0162191,19z/data=!4m2!4m1!3e0" target="_blank" rel="noopener">вул.Стрийська, 1Б, с.Солонка, Львівська обл.</a></p>
				<ul 
				data-aos="fade-right"
				data-aos-delay="300"
				data-aos-duration="1000"
				class="contacts-list">
					<li><a href="mailto:lembergflowers@gmail.com">lembergflowers@gmail.com</a></li>
					<li><a href="tel:+380503988988">+38 (050) 39 88 988</a></li>
				</ul>
				<ul 
				data-aos="fade-left"
				data-aos-delay="300"
				data-aos-duration="1000"
				class="social-list">
					<li>
						<a href="https://www.facebook.com/lembergflowers.ltd" target="_blank" rel="noopener">Facebook</a>
					</li>
					<li><a href="https://www.instagram.com/lembergflowers/" target="_blank" rel="noopener">instagram</a></li>
				</ul>
			</div>
			<div>
				<iframe title="Lemberg Flowers address map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2577.134609317731!2d24.01366591519!3d49.764724844417024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473ae9576afe5ad9%3A0x3b2b984e5ca46d32!2z0JvQtdC80LHQtdGA0LMt0KTQu9Cw0LLQtdGA0YE!5e0!3m2!1suk!2sua!4v1636914139391!5m2!1suk!2sua" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
  @import "../assets/scss/components/contacts.scss";
</style>
<script>
export default {
	
}
</script>